.sidebar {
    background-color: #8fbd7e;
    height: 100vh;
    top: 0;
    left: 0;
    width: 30%;
    float: left;
}

.sidebar-wrapper {
    padding-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    min-height: 100vh;
    position: relative;
}

.btn-sidebar {
    border-radius: 5pt;
    font-weight: bold;
    text-transform: uppercase;
    background-color: white;
    color: #8fbd7e;
    font-size: 24pt;
    padding: .5rem 2rem;
    margin-right: 1rem;
    cursor: pointer;
    border: 2pt solid white;
    transition-duration: .2s;
}

.btn-sidebar:hover {
    background-color: #8fbd7e;
    color: white;
    border: 2pt solid white;
}

.sidebar-header {
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    font-size: 32pt;
    margin-top: 1rem;
}

.sidebar-subheader {
    margin-bottom: 0;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    font-size: 24pt;
}

.sidebar-form {
    color: white;
    font-size: 18pt;
    overflow-x: hidden;
}

#display-overflow {
    overflow-x: visible;
}

.form-check-input {
    border: 2px solid #8fbd7e !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238fbd7e'/%3e%3c/svg%3e") !important;
    background-color: white !important;
    outline: 0 !important;
    box-shadow: none !important;
    cursor: pointer !important;
}

.sidebar-profile {
    background-color: #9acf91;
    border-radius: 15pt;
    min-height: 50pt;
    box-shadow: 2px 3px 14px -1px rgba(0,0,0,0.3);
    position: absolute;
    bottom: 15pt;
    width: 100%;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition-duration: .5s;
}

.sidebar-profile:hover {
    box-shadow: 2px 3px 14px 3px rgba(0,0,0,0.3);
}

.sidebar-profile-avatar {
    padding-top: .8rem;
    margin-left: .8rem;
    float: left;
    max-width: 50pt;
}

.sidebar-profile-username {
    font-size: 18pt;
    float: right;
    max-width: 60%;
    padding-left: 0;
    text-transform: uppercase;
}

.log-in {
    padding-top: .8rem;
}

.logged-in {
    margin: 0;
}

.username {
    font-size: 15pt;
    margin-top: -.5rem;
    font-weight: 500;
}

#error-message {
    margin-bottom: -1rem;
    padding: .3rem .5rem;
    border: 1pt solid red;
    background-color: rgba(173, 42, 19, .6);
    font-size: 14pt;
    text-transform: uppercase;
    color: white;
    font-weight: 500;
    border-radius: 7pt;
    display: none;
}

.login-form {
    min-width: 30% !important;
    background-color: #8fbd7e;
}

.login-form-header {
    font-size: 32pt;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
}

.login-form-input {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: white;
    color: #8fbd7e;
    font-size: 18pt;
    font-weight: 600;
    padding-bottom: .6rem;
    margin-top: 1rem;
}

.login-form-input:focus {
    color: #8fbd7e;
}

.login-form-input::placeholder {
    color: #8fbd7e;
}

.btn-login {
    margin-top: 1rem;
    font-size: 18pt;
    width: 100%;
}

.btn-logout {
    font-size: 18pt;
    position: absolute;
    bottom: 15pt;
    left: 16pt;
}

.recipe-container {
    float: left;
    height: 93vh;
    width: 66%;
    border: 5px solid #8fbd7e;
    margin-left: 2rem;
    margin-top: 2rem;
    padding: 0;
}

.recipe-header {
    position: relative;
    height: 40%;
    background-position: center center;
    background-size: cover;
}

.recipe-title {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    padding: 0 2rem .5rem 2rem;
    width: 100%;
    background-color: rgba(0, 0, 0, .4);
    color: white;
    font-weight: bold;
    font-size: 34pt;
}

.recipe-extra {
    margin: 0 0 0 2rem;
    font-weight: 300;
    font-size: 26pt;
    display: inline;
    text-transform: full-width;
}

.recipe-difficulty {
    display: inline;
    float: right;
    margin: 0 2rem 0 0;
    font-size: 26pt;
    text-transform: uppercase;
    font-weight: 300;
}

.recipe-labels-list {
    text-transform: uppercase;
    padding: 0;
    margin-left: 2rem;
}

.recipe-labels-list > li {
    display: inline;
    margin-right: 1rem;
}

.recipe-details {
    text-transform: uppercase;
    padding: 0;
    margin-left: 2rem;
    list-style-type: none;
}

.recipe-ingredients-row {
    float: left;
    margin-left: 2rem;
}

.recipe-ingredients-list {
    padding: 0;
    max-width: 400pt;
    margin-right: 4rem;
}

.recipe-allergens-list {
    padding: 0;
    max-width: 200pt;
    margin-right: 4rem;
}

.recipe-ingredients-list > ul {
    columns: 2;
}

.recipe-ingredients-list > ul > li {
    min-width: 200pt;
    margin-right: .5rem;
}

.recipe-ingredients-row > .recipe-ingredients-list > h3 {
    font-size: 24pt;
}

.recipe-ingredients-row > .recipe-ingredients-list > ul {
    margin-top: 0;
}

.recipe-button {
    display: inline-block;
    float: right;
    margin-right: 2rem;
    font-size: 30pt;
    text-transform: uppercase;
    font-weight: bold;
    padding: .5rem 1rem;
    background-color: #8fbd7e;
    border: 2pt solid #8fbd7e;
    border-radius: 5pt;
    color: white;
    text-decoration: none;
    transition-duration: .2s;
}

.recipe-button:hover {
    background-color: white;
    color: #8fbd7e;
    border: 2pt solid #8fbd7e;
}

.no-results-wrapper {
    text-align: center;
    text-transform: uppercase;
    color: #8fbd7e;
    margin: 10rem auto 0;
}

.no-results {
    width: 60%;
}

.notification .notification-header {
    color: white;
    background-color: #8fbd7e;
    border-radius: 5pt;
}

.notification {
    background-color: #8fbd7e;
    border: 1px solid #8fbd7e;
}

.notification-header {
    border-bottom: none;
}